// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      const blockchain = store
        .getState()
        .blockchain
      let totalSupply = await blockchain
        .smartContract.methods.totalSupply()
        .call();
      let phase = await blockchain
        .smartContract.methods.phase()
        .call();
      let minted = await blockchain
        .smartContract.methods.minted(blockchain.account)
        .call()
      let whitelistMinted = await blockchain
        .smartContract.methods.whitelistMinted(blockchain.account)
        .call()
      let mintCost = await blockchain
        .smartContract.methods.mintCost()
        .call()
      let witelistMintCost = await blockchain
        .smartContract.methods.witelistMintCost()
        .call()
      let whitelistSupply = await blockchain
        .smartContract.methods.whitelistSaleSupply()
        .call()
      let whitelisted = await blockchain
        .smartContract.methods.whitelist(blockchain.account)
        .call()
      let saleSupply = await blockchain
        .smartContract.methods.saleSupply()
        .call()

      dispatch(
        fetchDataSuccess({
          totalSupply,
          phase,
          minted,
          whitelistMinted,
          mintCost,
          witelistMintCost,
          whitelistSupply,
          saleSupply,
          whitelisted
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
