import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import { Provider } from "react-redux";
import "./styles/reset.css";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

const theme = createTheme({
  props: {
    MuiTextField: {
      variant: "outlined"
    }
  },
  typography: {
    fontSize: 12,
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    button: {
      textTransform: "none"
    }
  },
  palette: {
    primary: {
      main: '#ae3500',
    },
    secondary: {
      main: '#fef4f4',
    },
  },
  mixins: {
    toolbar: {
      minHeight: 42
    }
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
