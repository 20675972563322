const initialState = {
  loading: false,
  totalSupply: 0,
  phase: 0,
  minted: 0,
  whitelistMinted: 0,
  mintCost: 0,
  witelistMintCost: 0,
  whitelistSupply: 0,
  saleSupply: 0,
  whitelisted: false,
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        totalSupply: action.payload.totalSupply,
        phase: action.payload.phase,
        minted: action.payload.minted,
        whitelistMinted: action.payload.whitelistMinted,
        mintCost: action.payload.mintCost,
        witelistMintCost: action.payload.witelistMintCost,
        whitelistSupply: action.payload.whitelistSupply,
        saleSupply: action.payload.saleSupply,
        whitelisted: action.payload.whitelisted,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
